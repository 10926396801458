const it = {
  navigation: {
    plantHalts: "Fermi impianto",
    plantHaltsList: "Elenco fermi",
    plantHaltsToCheck: "Da motivare"
  },
  list: {
    exportData: "Esporta dati",
    searchPlaceholder: "Filtro campo testuali...",
    noData: "Nessun dato da visualizzare",
    timeHalted: "Altri fermi",
    timePaused: "Pause imgombranti",
    timeTotal: "Totale",
    timeHaltTotal: "Tempo totale",
    totalUnjustified: "Arresti da motivare",
    totalUnjustifiedPast: "Arresti non motivati",
    totalHalts: "Fermi totali",
    manMorningDelay: "Ritardo all'avvio",
    manPauseDelay: "Ritardo dopo pausa",
    plantOfficeHours: "Orari apertura impianto",
    notMotived: "Non motivato",
    empties: "Svuotamenti",
    confirmedBy: "Confermato da",
    filter: {
      allhalts: "tutti i fermi",
      cause: "Tipo causa",
      causeDetails: "Dettaglio causa",
      noCauses: "Filtro non disponibile",
      onlyUnjustified: "solo non motivati",
      hideDownstreamLine: "nascondi linea a valle",
      allCauses: "Mostra tutte",
      allDetails: "Mostra tutti",
      from: "Dal",
      to: "Al",
      bulky: "nascondi pause ingombranti",
      filter999: "Mostra solo fermi 999 (tot. {{count}})",
      zone: "Zona",
      downstreamLine: "Linea a valle",
    },
    page: {
      label: "Pagina",
      of: "di",
      rows: "righe"
    },
    action: {
      confirm: "Motiva",
      update: "Modifica",
      select: "SELEZIONA..."
    }
  },
  detail: {
    title: "Fermo impianto",
    action: {
      confirm: "Giustifica",
      update: "Modifica"
    },
    time: "Tempo",
    from: "Dal",
    to: "Al",
    duration: "Durata",
    cause: "Causa",
    asset: "Cespite",
    causeDetails: "Dettaglio causa",
    notes: "Note",
    noCauseDefined: "Non definito",
    zone: "Zona"
  },
  modal: {
    cancel: "Annulla",
    save: "Salva",
    frequentsNotes: "Note frequenti"
  },
  unit: "Unità"
};

export default { plantHalts: it };
