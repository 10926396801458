import FetchWrapper from '../../inplant-core-fe/functions/fetchWrapper'
import { default as FetchWrapperCoreAdapter } from "../../inplant-coreadapter-fe/functions/fetch-wrapper"

const API_BASE_URL = (window as any).API_BASE_URL || process.env.REACT_APP_API_BASE_URL
const APIbaseURL = `${API_BASE_URL}/v1`

const initFetchWrapper = (i18n: any) => {
  FetchWrapperCoreAdapter.configInstance('auth', APIbaseURL, true, i18n)
  FetchWrapperCoreAdapter.configInstance('user', APIbaseURL, true, i18n)
  FetchWrapperCoreAdapter.configInstance('checklist', `${APIbaseURL}/checklist-compiler`, true, i18n)
  FetchWrapperCoreAdapter.configInstance('chronoframe', APIbaseURL, true, i18n)
  FetchWrapperCoreAdapter.configInstance('mcs', `${APIbaseURL}/mcs`, true, i18n)
  FetchWrapperCoreAdapter.configInstance('plantHalts', APIbaseURL, true, i18n)
  FetchWrapperCoreAdapter.configInstance('file', '', true, i18n)
  FetchWrapperCoreAdapter.configInstance('titech', APIbaseURL, true, i18n)
  FetchWrapper.configInstance('recipe-control', `${APIbaseURL}`, true, i18n)
  FetchWrapperCoreAdapter.configInstance('checklistIA', `${APIbaseURL}`, true, i18n)
  FetchWrapper.configInstance('proximity',APIbaseURL, true, i18n)
  FetchWrapperCoreAdapter.configInstance('assetManager', `${APIbaseURL}/asset-manager`, true, i18n)
}

export default initFetchWrapper
